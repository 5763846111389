/* eslint-disable react/no-danger */
import React, { FC, useEffect } from "react";
import { useStores } from "@stores";
import { observer } from "mobx-react";
import { graphql } from "gatsby";
import { TextSection } from "@components/TextSection";
import { ContentPageLayout } from "@components/Layout";
import { ContactInformationSection } from "@components/ContactInformationSection/ContactInformationSection";
import {
  Box,
  ShadowBlock,
  Spinner,
  TwoColorButton,
} from "@components/Primitives";
import { CONTACT_SHORTCUTS, PATHS } from "../../constants/nav-items";
import { ShortcutBox } from "@components/BlueBox";
import styled from "styled-components";
import theme from "@styles";
// @ts-ignore
import ShortcutMail from "@icons/shortcut_mail.svg";
import { FormattedMessage as M, navigate } from "gatsby-plugin-intl";

interface ICustomerServicePage {
  data: IMarkdownData;
}

const MessageIconContainer = styled.div`
  background-color: ${theme.colors.mainBlue};
  min-height: 4rem;
  min-width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
`;

const MessageButton = styled(ShadowBlock)`
  display: flex;
  align-items: center;
  max-width: 350px;
  cursor: pointer;
`;

const MessageButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

const CustomerServicePage: FC<ICustomerServicePage> = observer(
  ({
    data: {
      markdownRemark: {
        frontmatter: { blocks: texts },
      },
    },
  }) => {
    const {
      commonDataStore: { getContacts, state, contactInformation },
      agreementStore: { selectedAgreement },
    }: {
      commonDataStore: ICommonDataStore;
      agreementStore: IAgreementStore;
    } = useStores();

    const isLoading = state === "Loading";

    useEffect(() => {
      if (!isLoading && !contactInformation) {
        getContacts();
      }
    }, [selectedAgreement]);

    if (!contactInformation) return <ContentPageLayout />;

    const getTexts = (id: string) => texts.find((block) => block.id === id);

    const customerServiceBlocks = contactInformation.filter(
      (info) => info.contact_type === "customerservice"
    );

    const renderSection = (id: string, blocks: any) => (
      <>
        <Box mt="2" mb="2">
          <TextSection
            level="2"
            alignment="center"
            title={getTexts(id)?.title}
            text={getTexts(id)?.text}
          />
        </Box>

        {selectedAgreement && selectedAgreement.estate && (
          <ContactInformationSection contactInformation={blocks} />
        )}
      </>
    );

    const onSendMessageClick = () => {
      navigate(PATHS.messages.index);
    };
    return (
      <ContentPageLayout wideContent>
        <TextSection
          title={<M id="fields.label.customerService.title" />}
          text={<M id="fields.label.customerService.text" />}
        />

        <MessageButtonContainer onClick={onSendMessageClick}>
          <MessageButton pl="0" pr="1" pt="0" pb="0" minHeight="0">
            <MessageIconContainer>
              <ShortcutMail />
            </MessageIconContainer>
            <M id="pages.contactInformation.sendMessage" />
          </MessageButton>
        </MessageButtonContainer>

        {isLoading && <Spinner color="mainBlue" />}
        {!isLoading && (
          <>
            <TextSection
              title={<M id="fields.label.customerService.subtitle" />}
              text={<M id="fields.label.customerService.subtext" />}
            />
            <Box>{renderSection("customerservice", customerServiceBlocks)}</Box>
          </>
        )}
        <ShortcutBox items={CONTACT_SHORTCUTS} />
      </ContentPageLayout>
    );
  }
);

export const pageQuery = graphql`
  query ContactCustomerServiceQuery {
    markdownRemark(
      frontmatter: { path: { eq: "/contact/customer-service/" } }
    ) {
      frontmatter {
        path
        blocks {
          title
          text
          id
        }
      }
    }
  }
`;

export default CustomerServicePage;
